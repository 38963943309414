.num {
	width: 33.33%;
	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	border: 1px solid darkgray;
	font-size: 4rem;
	user-select: none;
	background: lightgray;

	&:hover {
		background: darkgray;
	}

	&[disabled] {
		opacity: .5;
	}
}