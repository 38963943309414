.password {
	display: flex;
	width: 100vw;
	height: 100vh;
	align-items: center;
	justify-content: center;

	&.success {
		background: forestgreen;
	}

	&.fail {
		background: red;
	}

	&__container {
		display: flex;
		flex-direction: column;
		width: 80%;
		height: 80%;
		background: lightgrey;
	}

	.display {
		display: flex;
		justify-content: center;
		box-sizing: border-box;
		border: 2px solid darkgray;
		border-bottom: none;

		.guess {
			font-size: 4rem;
		    margin: 0.5rem 1rem;
		    min-height: 6.9rem;
		}
	}

	.numpad {
		flex-grow: 1;
		display: flex;
		flex-wrap: wrap;
		box-sizing: border-box;
		border: 1px solid darkgray;
	}
}